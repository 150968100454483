export const niceNumbers = (n) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export const makePoint = (x, y) => {
    return {
        type: "Feature",
        properties: {},
        geometry: {
            type: "Point",
            coordinates: [x, y]
        }
    };
};
export const makeMultiPolygon = (geom) => {
    return {
        type: "Feature",
        properties: {},
        geometry: {
            type: "MultiPolygon",
            coordinates: geom
        }
    };
};
